.App {
  margin: 0 auto;
  max-width: 960px;
  padding: 40px;
  text-align: left;
  color: #000;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header img {
  width: 200px;
  border-radius: 100%;
  border: 6px solid #5e4e1044;
  padding: 4px;
}

h1 {
  color: #102e5e;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 8px;
}

h2 {
  margin-top: 60px;
}

p {
  color: #444;
  line-height: 1.4;
}

.subtitle {
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .App {
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    margin-top: 24px;
  }
}
