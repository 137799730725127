.word {
  background-color: #ffffff;
  border-left: 6px solid #102e5e;
  margin: 20px 0;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  overflow: hidden;
}

p {
  margin: 0;
}

.word-header {
  display: flex;
  align-items: flex-start;
}

.word-header-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 4px;
}

.word-image {
  width: 150px;
  margin-right: 20px;
  border-radius: 8px;
}

.small-word-image {
  width: 20px;
  margin-right: 4px;
  border-radius: 3px;
  /* filter: grayscale(100%); */
}

.word-title {
  font-size: 1.8rem;
  margin: 0;
  width: calc(100% - 130px);
  color: hsl(217 10% 70% / 1);

  .word-name {
    &.active {
      color: #102e5e;
      white-space: nowrap;
    }

    &:not(.active) {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: hsl(217 25% 33% / 1);
      }
    }
  }
}

.word-definition {
  font-size: 18px;
}

.word-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

label {
  display: block;
}

.label {
  font-weight: 700;
  color: #4f5155;
  margin-bottom: 5px;
}

.word-examples {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 8px;
}

.word-examples ul {
  margin: 0;
}

.example {
  font-style: italic;
  margin-bottom: 8px;
  font-weight: 400;
}

.word-part-of-speech {
  background: #102e5e;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
  /* border-top-left-radius: 16px; */
  border-bottom-left-radius: 16px;
  padding: 4px 12px;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .word {
    flex-direction: column;
    border-left-width: 0;
    border-bottom: 6px solid #102e5e;
  }

  .word-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .word-image {
    width: 250px; /* Larger image for smaller screens */
    margin-bottom: 10px;
  }

  .word-title {
    font-size: 1.5rem;
    margin-top: -48px;
    width: 100%;
    padding: 20px 0;
    z-index: 10;
  }

  .img-wrapper {
    position: relative;
  }

  .gradient {
    position: absolute;
    left: -24px;
    bottom: -24px;
    width: calc(100% + 24px * 2);
    z-index: 1;
    background: linear-gradient(0deg, #fff, transparent);
    height: 150px;
  }

  .word-part-of-speech {
    top: 12px;
    font-size: 12px;
    border-top-left-radius: 16px;
  }

  ul {
    padding-left: 24px;
  }

  .word-image {
    width: calc(100% + 24px * 2);
    margin: -24px;
  }

  .word-header-info {
    z-index: 1;
  }
}
